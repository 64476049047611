.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tipper-app {
  /* position: relative; */
  /* grid-template-rows: auto 1fr auto; */
}

.tipper-tabs {
  /* position: absolute; */
  /* margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: center; */
  /* padding: 20px 50px; */
}

.routes-contact-us-wrapper {
  /* padding: 20px 100px; */
}

@media (max-width: 768px) {
  .routes-contact-us-wrapper {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .routes-contact-us-wrapper {
    padding: 5px;
  }
}

.footer-wrapper {
  /* grid-row: 3; */
}

.contact-us {
  /* margin-right: 100px; */
  border-radius: 50px;
}

.footer {
}
